import React from "react";
import { Button, Form, Input } from "antd";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import * as AuthService from "../services/AuthService";
import Logo from '../assets/images/logo.jpg';

const SignUp = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const onFinish = (values) => {
		let formData = {};
       Object.entries(values).forEach(([key, value]) => {
       formData[key] = value;
       });

		AuthService.registerCompany(formData)
			.then((response) => {
				Swal.fire({
					title: "Success",
					text: "Account Created",
					icon: "success",
				});
				navigate("/");
			})
			.catch((err) => {
				let message = err.response?.data?.message;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="authWrapper">
			<div className="innerWrapper">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10">
							<div className="logoSection">
								<img src={Logo} className="img-fluid" alt="logo" />
							</div>
							<div className="card authCard">
								<div className="card-body">
									<div className="formWrapper">
										<div className="headingWrapper">
											<h2 className="heading">Sign Up</h2>
											<p className="subText">
												Harness the power of SWOT analysis to strategically
												assess your strengths, weaknesses, opportunities, and
												threats.
											</p>
										</div>
										<Form
											form={form}
											className="formMain authForm"
											name="basic"
											title="Register"
											initialValues={{
												remember: true,
											}}
											onFinish={onFinish}
											onFinishFailed={onFinishFailed}
											autoComplete="off"
											layout="vertical"
										>
											<div className="row">
												<div className="col-md-3">
													<Form.Item
														className="formContent"
														label="Company Name"
														name="company_name"
														rules={[
															{
																required: true,
																message: "Please input your company_name!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Company Name"
														/>
													</Form.Item>
												</div>
												<div className="col-md-6">
													<Form.Item
														className="formContent"
														label="Company Address"
														name="company_address"
														rules={[
															{
																required: true,
																message: "Please input your company address!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Company Address"
														/>
													</Form.Item>
												</div>

											</div>

											<hr style={{ marginTop: 0, borderColor: "#666666" }} />
											<div className="row">
												<div className="col-md-3">
													<Form.Item
														className="formContent"
														label="Admin Name"
														name="admin_name"
														rules={[
															{
																required: true,
																message: "Please input your admin_name!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Enter Name"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														className="formContent"
														label="Admin Email"
														name="admin_email"
														rules={[
															{
																type: "email",
																required: true,
																message: "Please input your admin email!",
															},
														]}
													>
														<Input
															className="formControl"
															placeholder="Enter email here"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														label="Password"
														className="formContent"
														name="password"
														rules={[
															{
																required: true,
																message: "Please input your password!",
															},
															{
																min: 6,
																message:
																	"Password must be at least 6 characters long!",
															},
															({ getFieldValue }) => ({
																validator(_, value) {
																	if (
																		!value ||
																		/[!@#$%^&*(),.?":{}|<>]/.test(value)
																	) {
																		return Promise.resolve();
																	}
																	return Promise.reject(
																		new Error(
																			"Password must contain at least one special character!"
																		)
																	);
																},
															}),
														]}
													>
														<Input.Password
															className="formControl"
															placeholder="Enter password here"
														/>
													</Form.Item>
												</div>
												<div className="col-md-3">
													<Form.Item
														label="Confirm Password"
														className="formContent"
														name="confirm_password"
														rules={[
															{
																required: true,
																message: "Please confirm your password!",
															},

															({ getFieldValue }) => ({
																validator(_, value) {
																	if (
																		!value ||
																		getFieldValue("password") === value
																	) {
																		return Promise.resolve();
																	}
																	return Promise.reject(
																		new Error(
																			"The new password that you entered do not match!"
																		)
																	);
																},
															}),
														]}
													>
														<Input.Password
															className="formControl"
															placeholder="Enter confirm password here"
														/>
													</Form.Item>
												</div>
												<div className="col-md-12 text-center">
													<div className="formContent">
														<Button
															className="btnPrimary w-30"
															type="primary"
															htmlType="submit"
														>
															Sign Up
														</Button>
													</div>
												</div>
												<div className="col-md-12 bottomText">
													Already have an account?{" "}
													<Link to="/" className="bottomTextLink">
														Login
													</Link>
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SignUp;
