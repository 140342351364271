import React, { useState } from "react";
import { Button, Spin, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";

export default function EditTemplate() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [form] = Form.useForm();

    const sunEditorOptions = {
        height: 300,
        buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor", "align", "list", "lineHeight"],
            ["outdent", "indent"],
            [
                "table",
                "link",
                "image",
                "video",
                "fullScreen",
                "showBlocks",
                "codeView",
            ],
            ["preview", "print"],
        ],
    };

    const onFinish = (values) => {
        console.info({ values });
    };

    const onFinishFailed = (error) => {
        console.error({ error });
    };

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 pb-5">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="pageHeader d-flex justify-content-between align-items-start analysisPageHeader">
                                    <h3>Edit Template</h3>
                                    <Button onClick={()=> navigate(-1)} className="btnFilled">
                                        <IoMdArrowRoundBack /> Back
                                    </Button>
                                </div>
                            </div>
                            <div className="edit-email-template-wrapper col-md-12">
                                <Form
                                    form={form}
                                    className="formMain edit-template-form"
                                    name="basic"
                                    title="Register"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item
                                                className="formContent"
                                                label="Subject"
                                                name="subject"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input the subject!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Please Enter Subject"
                                                    className="formControl"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Item
                                                className="formContent"
                                                label="Description"
                                                name="description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input description!",
                                                    },
                                                ]}
                                            >
                                                <SunEditor
                                                className="sun-editor"
                                                    setOptions={sunEditorOptions}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <div className="formContent">
                                                <Button
                                                    className="btnPrimary w-30"
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
}
