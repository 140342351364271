import React, { useState } from "react";
import { Spin, Card, Table, Button } from "antd";
import { useNavigate } from "react-router-dom";

const Templates = () => {
	const navigate = useNavigate()
	const [loader, setLoader] = useState(false);

	const emailTemplatesData = [
		{
			name: "Template 1",
			subject: "Lorem Ipsum is simply dummy"
		},
		{
			name: "Template 2",
			subject: "Lorem Ipsum is simply dummy"
		},
		{
			name: "Template 3",
			subject: "Lorem Ipsum is simply dummy"
		},
		{
			name: "Template 4",
			subject: "Lorem Ipsum is simply dummy"
		},

	]

	const emailTemplatesColumn = [
		{
			title: "S.NO",
			dataIndex: "sno",
			key: "sno",
			render: (text, record, index) => (
				<>
					<span className="template-index">{String(index + 1).padStart(2, "0")}</span>
				</>
			),
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text, record) => (
				<>
					<span className="template-name">{record?.name}</span>
				</>
			),
		},
		{
			title: "Subject",
			dataIndex: "subject",
			key: "subject",
			render: (text, record) => (
				<>
					<span className="template-subject">{record?.subject}</span>
				</>
			),
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (text, record) => (
				<>
					<div className="actionButtons">
						<Button
							className="btnOutlined me-2"
							style={{ height: "auto" }}
						>
							View
						</Button>
						<Button
							className="btnFilled"
							style={{ height: "auto" }}
							onClick={()=> navigate("/edit-template")}
						>
							Edit
						</Button>
					</div>

				</>
			),
		},
	]
	return (
		<>
			{loader ? (
				<div className="spinner">
					<Spin />
				</div>
			) : (
				<div className="mainWrapper">
					<Card className="card contentCard border-0 pb-5">
						<div className="row">
							<div className="col-md-12 mb-4">
								<div className="pageHeader d-flex justify-content-between align-items-center analysisPageHeader">
									<h3>Template List</h3>
								</div>
							</div>
							<div className="table-responsive">
								<Table
									dataSource={emailTemplatesData}
									columns={emailTemplatesColumn}
									pagination={{
										showSizeChanger: true,
									}}
									sortDirections={["descend", "ascend", "descend"]}
									defaultSortOrder={"descend"}
									showSorterTooltip={false}
								/>
							</div>
						</div>
					</Card>
				</div>
			)}
		</>

	);
};

export default Templates;
