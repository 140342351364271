import React, { useState, useEffect } from "react";
import { Button, Form, Input, Card, InputNumber, Select, Row, Col } from "antd";
import Swal from "sweetalert2";
import * as AuthService from "../../services/AuthService";
import { FaMinusCircle } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { CiEdit } from "react-icons/ci";

const UpdateCompany = () => {
    // const [fileList, setFileList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [company, setCompany] = useState({});
    const [countryCode, setCountryCode] = useState('');
    const [dropdownKeys, setDropdownKeys] = useState([])
    const [form] = Form.useForm();

    const handleCountryChange = (value, option) => {
        setStates([])
        form.setFieldsValue({ state: '' });
        form.setFieldsValue({ city: '' });
        form.setFieldsValue({ timezone: '' });
        setCountryCode(option.key)
        getState(option.key)
        getTimeZone(option.key);
    };

    const handleStateChange = (value, option) => {
        getCity(option.key, countryCode)
        form.setFieldsValue({ city: '' });
    };
    // const navigate = useNavigate();

    const { Option } = Select;

    // const props = {
    //   beforeUpload: (file) => {
    //     if (file.size <= 2000000) {
    //       if (fileList < 1) {
    //         setFileList([...fileList, file]);
    //         return false;
    //       } else {
    //         console.log("err");
    //       }
    //     } else {
    //       console.log("err");
    //     }
    //   },
    //   fileList,
    // };

    const onFinish = (values) => {
        AuthService.updateCompany(values, 1)
            .then((response) => {
                Swal.fire({
                    title: "Success",
                    text: "Company Details Updated",
                    icon: "success",
                });
                // navigate("/");
            })
            .catch((err) => {
                let message = err.response.data.message;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const getCountry = () => {
        AuthService.getCountry()
            .then((response) => {
                setCountries(response);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const getState = (country_code) => {
        AuthService.getState(country_code)
            .then((response) => {
                setStates(response);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const getCity = (state_code, country_code) => {
        AuthService.getCity(state_code, country_code)
            .then((response) => {
                setCities(response);
            })
            .catch((err) => {
                let message = err.response?.data?.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };
    const getTimeZone = (country_code) => {
        AuthService.getTimeZone(country_code)
            .then((response) => {
                setTimeZones(response);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const getCompany = () => {
        AuthService.getCompany()
            .then((response) => {
                setCompany(response);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    useEffect(() => {
        getCountry();
        getCompany()
    }, []);

    const KeysDropdown = [
        {
            id: 1,
            name: "Open AI"
        },
        {
            id: 2,
            name: "Stripe"
        },
        {
            id: 3,
            name: "Google"
        },
        {
            id: 4,
            name: "Snadgrid"
        },

    ]


    const renderKeys = () => {
        const addRow = () => {
            setDropdownKeys([
                ...dropdownKeys,
                { id: null, discount: "" },
            ]);
        };

        const removeRow = (index) => {
            setDropdownKeys(dropdownKeys.filter((_, idx) => idx !== index));
        };

        const handleSelectChange = (value, index) => {
            setDropdownKeys(
                dropdownKeys.map((row, idx) =>
                    idx === index ? { ...row, id: value } : row
                )
            );
        };

        const handleInputChange = (e, index) => {
            setDropdownKeys(
                dropdownKeys.map((row, idx) =>
                    idx === index ? { ...row, discount: e.target.value } : row
                )
            );
        };

        const getFilteredOptions = (currentIndex) => {
            const selectedValues = dropdownKeys
                .filter((_, idx) => idx !== currentIndex)
                .map((row) => row.id);

            return KeysDropdown
                .filter((option) => !selectedValues.includes(option.id))
                .map((option) => ({
                    value: option.id,
                    label: option.name,
                }));
        };

        return (
            <>
            <div className="d-flex justify-content-between align-items-center mb-3"> 
                <p
                    className="pricing-category-heading"
                    style={{
                        color:"#001529",
                        fontSize: "20px",
                        fontWeight: 500,
                        letterSpacing: "-0.66px"
                    }}
                >
                    Add Keys
                </p>
                <Button className="btnFilled"><CiEdit/> Edit</Button>
                </div>

                <div className="row keys-wrapper">
                    {dropdownKeys.map((row, index) => (
                        <>
                            <div className="keys-col col-3 mb-3">
                                <Select
                                    placeholder="Select Key"
                                    className="select-key-dropdown w-100"
                                    options={getFilteredOptions(index)}
                                    value={row.id || undefined}
                                    onChange={(value) => handleSelectChange(value, index)}
                                    required
                                />
                            </div>
                            <div className="keys-col col-6 mb-3">
                                <Input
                                    placeholder="Enter key"
                                    onChange={(e) => handleInputChange(e, index)}
                                    style={{ height: "100%" }}
                                    className="key-input"
                                    required
                                />
                            </div>
                            <div className="keys-col col-3 mb-3">
                                <Button
                                    className="remove-key-button"
                                    type="danger"
                                    onClick={() => removeRow(index)}
                                >
                                    <FaMinusCircle />
                                </Button>
                            </div>
                            </>
                    ))}
                </div>

                {dropdownKeys.length < KeysDropdown?.length && (
                    <div className="d-flex justify-content-center">
                    <Button
                        className="mb-3 btnFilled"
                        type="dashed"
                        onClick={addRow}
                    >
                        {dropdownKeys?.length === 0 ? "Add Keys" : "Add more"}{" "}
                        <span className="ms-2">
                            <IoMdAddCircle />
                        </span>
                    </Button>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {company?.company_name ?
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 mb-4">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="pageHeader">
                                    <h3 className="mb-4">Update Company</h3>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Form
                                    form={form}
                                    className="swotForm"
                                    name="basic"
                                    title="Register"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Company Name"
                                                name="company_name"
                                                initialValue={company?.company_name ? company.company_name : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your company_name!",
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-8">
                                            <Form.Item
                                                label="Company Address"
                                                className="formContent"
                                                name="company_address"
                                                initialValue={company?.company_address ? company.company_address : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your company_address!",
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Select Country"
                                                name="country"
                                                initialValue={company?.country ? company.country : ""}
                                            >

                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="select country"
                                                    onChange={handleCountryChange}
                                                    showSearch={true}
                                                >
                                                    {countries?.map((country) => {
                                                        return (
                                                            <Option key={country.country_code} value={country.country_name}>
                                                                {country.country_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Select State"
                                                name="state"
                                                initialValue={company?.state ? company.state : ""}
                                            >
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="select state"
                                                    onChange={handleStateChange}
                                                    showSearch={true}
                                                >
                                                    {states?.map((state) => {
                                                        return (
                                                            <Option key={state.state_code} value={state.state_name}>
                                                                {state.state_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Select City"
                                                name="city"
                                                initialValue={company?.city ? company.city : ""}
                                            >
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="select city"
                                                    showSearch={true}
                                                >
                                                    {cities?.map((city) => {
                                                        return (
                                                            <Option key={city.city_code} value={city.city_name}>
                                                                {city.city_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                name="timezone"
                                                initialValue={company?.timezone ? company.timezone : ""}
                                                label="Select Timezone"
                                            >
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="select TimeZone"
                                                >
                                                    {timeZones?.map((time_zone) => {
                                                        return (
                                                            <Option key={time_zone.id} value={time_zone.time_zone}>
                                                                {time_zone.time_zone}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Zip Code"
                                                className="formContent"
                                                name="zip"
                                                initialValue={company?.zip ? company.zip : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your zip code!",
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Admin Name"
                                                name="admin_name"
                                                initialValue={company?.admin_name ? company.admin_name : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your admin_name!",
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Admin Email"
                                                name="admin_email"
                                                initialValue={company?.admin_email ? company.admin_email : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your admin_email!",
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" disabled />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Contact Number"
                                                name="contact_number"
                                                initialValue={company?.contact_number ? company.contact_number : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your contact_number!",
                                                    },
                                                ]}
                                            >
                                                <InputNumber className="form-control" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Staff Size"
                                                initialValue={company?.staff_size ? company.staff_size : ""}
                                                className="formContent"
                                                name="staff_size"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input staff_size!",
                                                    },
                                                ]}
                                            >
                                                <InputNumber className="form-control" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item
                                                label="Color"
                                                name="color"
                                                initialValue={company?.color ? company.color : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input color!",
                                                    },
                                                ]}
                                            >
                                                <Input className="form-control" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <Button type="primary" htmlType="submit">
                                                Update
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <hr />
                                <div className="keys-wrapper">
                                    {renderKeys()}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div> : <></>
            }
        </>
    );
};
export default UpdateCompany;
