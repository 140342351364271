import React, { useState } from 'react';
import { Form, Input, Button, Upload } from "antd";

export default function CompanySettings({ onData }) {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);

    const onFinish = (values) => {
        console.info({ values })
        onData(values)
    }

    const onFinishFailed = (errorInfo) => {
        console.error("Failed:", errorInfo);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);



    const props = {
        beforeUpload: (file) => {
            if (file.size <= 2000000) {
                if (fileList < 1) {
                    setFileList([...fileList, file]);
                    return false;
                } else {
                    console.log("err");
                }
            } else {
                console.log("err");
            }
        },
        fileList,
    };

    return (
        <div>
            <Form
                form={form}
                className="formMain authForm"
                name="basic"
                title="Register"
                initialValues={{
                    remember: true,
                    theme_color: "#3A6193",
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
                <div className="row">
                    <div className="col-md-6">
                        <Form.Item
                            className="formContent"
                            label="Contact Number"
                            name="contact_number"

                        >
                            <Input
                                placeholder="Please Enter Contact Number"
                                className="formControl"
                                maxLength={10}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onPaste={(e) => {
                                    const paste = (
                                        e.clipboardData || window.clipboardData
                                    ).getData("text");
                                    if (!/^[0-9]+$/.test(paste)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item
                            className="formContent"
                            label="Number Of Staff"
                            name="number_of_staff"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Number of Staff!",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Please Enter Contact Number"
                                className="formControl"
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onPaste={(e) => {
                                    const paste = (
                                        e.clipboardData || window.clipboardData
                                    ).getData("text");
                                    if (!/^[0-9]+$/.test(paste)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Item
                            className="formContent"
                            label="Company Email Template Color Theme"
                            name="theme_color"

                        >
                            <Input
                                className="formControl"
                                placeholder="Enter Company Email Template Color Theme"
                                type='color'
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item
                            className="formContent"
                            label="Industry"
                            name="industry"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your industry!",
                                },
                            ]}
                        >
                            <Input
                                className="formControl"
                                placeholder="Enter Industry"
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item name="logo" label="Logo" className="logo">
                            <Upload
                                {...props}
                                accept="image/png, image/jpeg"
                                maxCount={1}
                                name="file"
                                listType="picture-card"
                                onChange={handleChange}
                                fileList={fileList}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>

                        </Form.Item>
                    </div>

                    <div className="col-md-12 text-center">
                        <div className="formContent">
                            <Button
                                className="btnPrimary w-30"
                                type="primary"
                                htmlType="submit"
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
