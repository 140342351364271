import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router-dom";
import {
    Department,
    Employees,
    Schedule,
    Swot,
    Dashboard,
} from '../constant/icons';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.jpg';

const { Sider } = Layout;

const SidebarComponent = () => {
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState();

    useEffect(() => {
        const path = location.pathname;
        let key = path;

        if (path === "/create-department") {
            key = "/department";
        } else if (path === "/create-employees") {
            key = "/employees";
        } else if (path === "/schedule-swot") {
            key = "/schedule-swotlist";
        } else if (path === "/swot-response" || path === "/swot_analysis") {
            key = "/swot-list";
        }

        setSelectedKey(key);
    }, [location.pathname]);

    const items = [
        {
            key: "/dashboard",
            icon: <Dashboard className="menu-icon" />,
            path: "/dashboard",
            label: <Link to={"/dashboard"}>{"Dashboard"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/department",
            icon: <Department className="menu-icon" />,
            path: "/department",
            label: <Link to={"/department"}>{"Department"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/employees",
            icon: <Employees className="menu-icon" />,
            path: "/employees",
            label: <Link to={"/employees"}>{"Employees"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/schedule-swotlist",
            icon: <Schedule className="menu-icon" />,
            path: "/schedule-swotlist",
            label: <Link to={"/schedule-swotlist"}>{"Schedule Swot List"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/swot-list",
            icon: <Swot className="menu-icon" />,
            path: "/swot-list",
            label: <Link to={"/swot-list"}>{"Swot Analysis"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/templates",
            icon: <Swot className="menu-icon" />,
            path: "/templates",
            label: <Link to={"/templates"}>{"Templates"}</Link>,
            className: "custom-menu-item",
        },
    ];

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{
                overflow: "auto",
                height: "100vh",
                minHeight: "100vh",
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
            className="sidebar"
        >
            <div className="demo-logo-vertical">
                <Link to={"/dashboard"}>
                    <img src={Logo} className="img-fluid" alt="logo" />
                </Link>
            </div>
            <Menu mode="inline" theme={'#09074D'} items={items} selectedKeys={[selectedKey]} />
        </Sider>
    );
};

export default SidebarComponent;
